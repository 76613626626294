/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};
  var guestUsersElements = [], returnUsersElements = [];
  var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;

  Drupal.behaviors.analyticsBehavior = {
    attached: 0,

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var positions = [];
      var i;
      var j = productIds.length;
      if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
        for (i = 0; i < j; i++) {
          positions.push(this.findElementIndex(prodcat.data.pids, productIds[i]));
        }
      }
      return positions;
    },
    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var self = this;
      var analyticsSettings = Drupal.settings.analytics ? Drupal.settings.analytics.analytics_tagging_enabled : false;
      var eventObj = {};

      if (self.attached) {
        return;
      }

      // Track Brand Logo
      $('.site-logo-responsive-v1 > a, .js-analytics-logo-icon-click a', context).on('click', function () {
        var obj;

        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      $(document).on('change', 'input[name="SHIP_METHOD_GROUP"]', function() {
        var group = $('input[name="SHIP_METHOD_GROUP"]').filter(':checked').val();
        var shipMethodLabel = parseInt(group) === 0 ? 'standard' : 'cc';
        var obj = {
          event_label: shipMethodLabel
        };
        site.track.evtAction('bopisSelectDeliveryOption', obj);
      });

      $(document).on('map.locationselected', function(event, locationObj) {
        var location = locationObj.location;
        var locationProvider = parseInt(location.ServiceId) === 1008 ? 'cc-iml' : 'cc-pp';
        var obj = {
          event_label: locationProvider
        };
        site.track.evtAction('bopisSelectDeliveryOption', obj);
      });

      // Track Product Click
      var prodClickSelectors = [
        '.js-product-image-section a'
      ];

      $(document).on('click', prodClickSelectors.join(', '), function (e) {

        $('.elc-product-brief.js-product-brief').each(function (index) {
          $(this).attr('data-index', index);
        });
        var $prodElem = $(this);
        var prodId = $prodElem.closest('.js-product-brief').attr('data-product-id');
        var incStr = prodId.includes('PROD');
        if (!incStr) {
          prodId = 'PROD' + prodId;
        }
        var prodPosition = Number($prodElem.closest('.js-product-brief').data('index')) + 1;
        var prodName = $prodElem.closest('.js-product-brief').find('.js-product-name-section a').attr('aria-label');
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name: [prodName]
          });
        }
      });

      // Regimen module Comparison Module - Track Product Click
      var regimenProdClick = [
        '.js-elc-regimen-product-image',
        '.js-elc-comparison-module__image'
      ];

      $(document).on('click', regimenProdClick.join(', '), function () {
        var $currentElem = $(this);
        var $prodElem = $(this).closest('.js-product');
        var prodId = $prodElem.attr('data-prod-id') || $prodElem.attr('data-product-id');
        var incStr = prodId.includes('PROD');
        var prodName = $prodElem.find('.js-elc-regimen-product-title').text() || $prodElem.find('.js-product-name').text();
        var prodPosition = 0;

        $('.js-product').each(function (index) {
          $(this).attr('data-index', index);
        });

        prodPosition = Number($prodElem.data('index')) + 1;

        if (!incStr) {
          prodId = 'PROD' + prodId;
        }

        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $currentElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name: [prodName],
            features_prod_click: true
          });
        }
      });
      // Product click on Recommendation section
      recomProductSelectors = [
        '.recommended-products__list--item .thumb-inner a',
        '.recommended-products__list--item .product-name a',
        '.recommended-product-items a.thumb',
        '.recommended-product-items .product_name a',
        '#works-well-with-wrapper a',
        '.recommended-product-items .quickshop a'
      ];

      $(recomProductSelectors.join(', '), context).on('click', function() {
        $('table td:odd').addClass('child-thumb');
        $('#works-well-with-wrapper .thumb, #works-well-with-wrapper .child-thumb, .recommended-product-items .recommended-item').each(function (index) {
          $(this).attr('data-index', index);
        });
        var $prodElem = $(this);
        var $prodHref = $prodElem.attr('href');
        var splitprod = $prodHref.split('/');
        var prodId = 'PROD' + splitprod[3];
        var prodPosition = Number($prodElem.closest('.thumb, .child-thumb, .recommended-item').data('index')) + 1;
        var prodName = $prodElem.closest('td').find('img.border').attr('alt') || $prodElem.text().trim() || $prodElem.closest('.recommended-item').find('.product_name').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name: [prodName]
          });
        }
      });

      // Track Quick Shop with different ID in MPP and Home Page
      $('a.js-mpp-quickshop, .btn-quickview, .recommended-product-items .quickshop a', context).on('click', function() {
        var obj;
        var position = 0;
        var $trigger = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodElem = $trigger.closest('.mpp-product-hover-view, .product, li.js-product-pr, .recommended-item');
        var prodId = $trigger.data('productid') || prodElem.data('productid');
        var prodName = prodElem.find('.product-info, .product_name').find('a h3, a span, a').text();
        if (!prodName) {
          prodName = $trigger.siblings('.product-info').find('.product-title > a').attr('title');
        }
        if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
          position = self.getProductPositions([prodId]);
        }
        obj = {
          event_label: prodName + ' - ' + prodId,
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.price, .product-price').text().replace(/\s+/g, ' ').trim()],
          product_position: position
        };
        site.track.quickView(obj);
      });

      //Track select shade/size for Mobile
      $('.js-quickshop-mobile, .mobile_mpp .select_shade', context).on('click', function() {
        var obj;
        var position = 0;
        var $targetElem = $(this);
        var $trigger = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodElem = $targetElem.closest('.product');
        var prodId = prodElem.attr('data-productid');
        var prodName = prodElem.children().children().find('h3 a').text();
        obj = {
          event_label: prodName + ' - ' + prodId,
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.product-info').find('p').text().replace(/\s+/g, ' ').trim()]
        };
        site.track.quickView(obj);
      });

      // Track Predictive Search Product Click and All Results Click
      $('.js-typeahead-gap-tag, .product-results').on('click', '.result', function() {
        var term = '';
        var $prod = $(this);
        var obj;
        var $prodWrapper = $prod.children('a.link');
        term = $('#search').val() || $('.search-term').val();
        if (!$prodWrapper.hasClass('link')) {
          $prodWrapper = $prod.parent('a');
        }
        self.setLinkCapture($prodWrapper.attr('href'), '_self');
        obj = {
          event_label: term,
          search_keyword: term,
          product_id: 'PROD' + $prodWrapper.attr('href').split('/')[3],
          product_name: $prodWrapper.text().trim()
        };
        site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
      });

      // CHECKOUT EVENTS

      // Track guest user checkout
      guestUsersElements = [
        '#checkout_signin_new_user .form-submit',
        '#checkout_signin_guest_user .form-submit',
        '#registration_short .form-submit',
        '.js-analytics-new-user-input-submit',
        '#checkout_registration .form-submit',
        '#checkout_signin_new_user .form-submit-checkout'

      ];
      $(document).on('click', guestUsersElements.join(', '), function() {
        var obj = {};

        if (window.location.href.indexOf('account') === -1) {
          site.track.evtAction('checkoutGuestUser', obj);
        }
      });

      // Track return user checkout
      returnUsersElements = [
        '#checkout-_-checkout_signin .form-submit',
        '#checkout-_-checkout_signin .signin-submit',
        '.js-analytics-checkout-return-user .form-submit',
        '#checkout_signin-submit'
      ];
      $(document).on('click', returnUsersElements.join(', '), function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      //Track Payment Methods
      $('#checkout_billing input.place-order', context).once('js-submit').each(function() {
        var $purchaseElem = $(this);
        $purchaseElem.on('click', function() {
          var paymentLabel = '';
          var paymentType = $('input[name$=PAYMENT_METHOD]:checked').val();
          if (paymentType === 'PP_MPCC') {
            paymentLabel = 'creditcard';
          } else if (paymentType === 'PP_CASH') {
            paymentLabel = 'cashondelivery';
          } else if (paymentType === 'PP_Braintree') {
            paymentLabel = 'paypal';
          } else if (paymentType === 'PP_WEBPAY') {
            paymentLabel = 'webpay/debit';
          }
          var obj = {
            event_label: paymentLabel
          };
          if (paymentLabel.length !== 0) {
            site.track.evtAction('checkoutPaymentSelected', obj);
          }
        });
      });

      var paymentMethodElements = [
        '#checkout_billing input#continue-checkout',
        '#checkout_complete input.js-continue-checkout',
        '#checkout_review input.form-submit',
        '#checkout_review input.continue-to-paypal',
        'input#continue-to-payment-btn',
        '#checkout_complete input.checkout-buttons',
        '#js-analytics-payment-submit',
        '.js-analytics-payment',
        '.js_analytics_checkout_payment'
      ];
      $(document).on('click', paymentMethodElements.join(', '), function() {
        var paymentLabel = '';
        var paymentTypePP = [
          'PayPal',
          'PP',
          'PAYMENT_OPTION_PAYPAL',
          'paypal',
          'PP_Braintree'
        ];
        var paymentTypeCC = [
          'Credit Card',
          'CC',
          'PAYMENT_OPTION_MC',
          'PAYMENT_OPTION_VISA',
          'PAYMENT_OPTION_PP',
          'PAYMENT_OPTION_CARDLINK',
          'credit_card',
          'GMO',
          'SecurePay',
          'PAYMENT_OPTION_CC',
          'PP_CC',
          'PP_MPWEB',
          'KBank',
          'PAYMENT_OPTION_NCCC_NORMAL',
          'PAYMENT_OPTION_NCCC_REDEMPTION',
          'Pelecard'
        ];
        var paymentTypeAP = [
          'AfterPay',
          'afterpay'
        ];
        var paymentTypeCOD = [
          'PAYMENT_OPTION_COD',
          'PP_CASH',
          'COD'
        ];
        var paymentTypeBT = [
          'BT'
        ];
        var paymentTypeLinePay = [
          'PAYMENT_OPTION_LINEPAY'
        ];
        var paymentTypeWebPay = [
          'PP_WEBPAY'
        ];
        var payment_type_mobile = $('input.js-paypal-checkbox:checked').val() === '1' ? $('input.js-paypal-checkbox:checked').val() : '';
        var paymentType = $('input[name$=PAYMENT_METHOD]:checked').val() || $('input[name$=PAYMENT_TYPE]:checked').val() || $('input[name$=PAYMENT_OPTION]:checked').val() || $('select[name=PAYMENT_OPTION] option:selected').val() || $('input[name=payment_select]:checked').val() || $('input[name=PP_NAME]:checked').val();
        if ($.inArray(paymentType, paymentTypePP) > -1 || payment_type_mobile === '1') {
          paymentLabel = 'paypal';
        } else if ($.inArray(paymentType, paymentTypeCC) > -1) {
          paymentLabel = 'creditcard';
        } else if ($.inArray(paymentType, paymentTypeCOD) > -1) {
          paymentLabel = 'cashondelivery';
        } else if ($.inArray(paymentType, paymentTypeAP) > -1) {
          paymentLabel = 'afterpay';
        } else if ($.inArray(paymentType, paymentTypeBT) > -1) {
          paymentLabel = 'banktransfer';
        } else if ($.inArray(paymentType, paymentTypeLinePay) > -1) {
          paymentLabel = 'Linepay';
        } else if ($.inArray(paymentType, paymentTypeWebPay) > -1) {
          paymentLabel = 'webpay/debit';
        }
        var obj = {
          event_label: paymentLabel
        };
        if (paymentLabel.length !== 0) {
          site.track.evtAction('checkoutPaymentSelected', obj);
        }
      });

      // Track Paypal Express Check-out
      $('a.paypal-checkout,.paypal-button a').on('click', function() {
        var obj = {
          event_label: 'paypal'
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });
      // END CHECKOUT EVENTS      

      //Clinical Reality Tagging start

      if ($('body').find('.js-ccr-landing-page').length > 0 || $('body').find('.clinical-reality-recommended-products-v1').length > 0) {
        cliniqueRealityTagging();
      }

      function cliniqueRealityTagging() {
        initRealityQuizTagging();

        var prodTitletop = $('.clinical-reality__recommended-products--product:eq(0)').text().trim();
        var prodTitlebottom = $('.clinical-reality__recommended-products--product:eq(1)').text().trim();

        function startAnalysisQuizTagging() {
          var $startSelector = $('.js-start-analysis', context).once('js-analytics-start');
          $startSelector.on('click', function() {
            var $pcSelector = $('.mobile_hidden');
            var introAction = $pcSelector.find('.clinical-reality__intro--title').text().trim();
            var clickLabel = $pcSelector.find('.js-start-analysis').text().trim();
            trackstartAnalysisEvent(introAction, clickLabel);
          });
        }

        function reanalysisQuizTagging() {
          var $reanalysisSelector = $('.js-ccr-recommended-products .js-results-retake', context).once('js-intro-retake');
          $reanalysisSelector.on('click', function() {
            var resultAction = $('h2.js-ccr-skin-concern').text().trim();
            var retake = $(this).text();
            trackstartAnalysisEvent(resultAction, 'Top - ' + retake);
          });
        }

        function learnMoreTagging() {
          var $learnMoreSelector = $('.js-learn-more', context).once('js-read-more');
          $learnMoreSelector.on('click', function() {
            var learnmoreLabel = $(this).text().trim();
            trackstartAnalysisEvent(prodTitletop + prodTitlebottom, ' Middle - ' + learnmoreLabel);
          });
        }

        function addtoBagTagging() {
          $(document).on('click', '.js-combined-products-add-to-bag', function() {
            var $prod = $(this);
            var addtobagLabel = $prod.text();
            var prodName = prodTitletop + prodTitlebottom;
            var prodId = $prod.data('product-id');
            trackstartAnalysisEvent(prodTitletop + prodTitlebottom, 'Middle - ' + addtobagLabel + ' - ' + prodName + ' - ' + prodId);
          });
        }

        function saveResultTagging() {
          var $saveResultSelector = $('.js-save-button', context).once('js-save-results');
          $saveResultSelector.on('click', function() {
            var saveResultAction = $('.clinical-reality__module--title-copy').text().trim();
            var saveResultLabel = $(this).text().trim();
            trackstartAnalysisEvent(saveResultAction, 'Middle - ' + saveResultLabel);
          });
        }

        function foundationMatchTagging() {
          var $foundationSelector = $('.js-mpp-product-v1', context).once('js-reality-mpp-product-v1');
          $foundationSelector.on('click', function() {
            var $elem = $(this);
            var founProdName = $elem.find('.product-title').text();
            var founProdid = $elem.data('productid');
            var foundationAction = $elem.closest('.clinical-reality-foundation-finder-v1, .clinical-reality__skin-products').find('.clinical-reality__module--title').text().trim();
            trackstartAnalysisEvent(foundationAction, founProdName + ' - ' + founProdid);
          });
        }

        function bottomSectionTagging() {
          var $bottomSelector = $('.js-reality-article', context).once('js-clinical-reality__article');
          $bottomSelector.on('click', function() {
            var $elam = $(this);
            var bottomLabel = $elam.text();
            var bottomAction = $elam.siblings().find('.article-title').text().trim();
            trackstartAnalysisEvent(bottomAction, 'Bottom - ' + bottomLabel);
          });
        }

        function trackstartAnalysisEvent(action, label) {
          var obj = {
            event_category: 'clinical reality',
            event_action: action,
            event_label: label
          };
          site.track.evtLink(obj);
        }

        function initRealityQuizTagging() {
          startAnalysisQuizTagging();
          reanalysisQuizTagging();
          learnMoreTagging();
          addtoBagTagging();
          saveResultTagging();
          foundationMatchTagging();
          bottomSectionTagging();
        }
      }

      //Clinical Reality Tagging end
      
      // Cliniqueid tagging start.
      if ($('body').hasClass('section-cliniqueid') && analyticsSettings) {
        trackCliniqueIdTagging();
      }

      function trackCliniqueIdTagging() {
        initCliniqueIdTagging();
        var baseProdId;
        var cartridgeProdId;
        var baseProdSku;
        var cartridgeProdSku;

        function trackBaseResult() {
          $('.js-mix-and-match--item-base', context).once('js-pick-base-step').each(function() {
            var $baseProdElem = $(this);
            $baseProdElem.on('click', function() {
              var $baseProdParent = $baseProdElem.closest('.js-mix-and-match--item-base--wrapper');
              var eventAction = $baseProdParent.find('.mix-and-match--title').text().trim();
              var eventLabel = $baseProdElem.data('final-step-title');
              baseProdId = $baseProdElem.data('prodid');
              baseProdSku = $baseProdElem.data('sku');
              trackEvent('customidfinder_Step1', 'diagnostics | Custom ID Finder', eventAction, eventLabel);
            });
          });
        }

        function trackCartridgeResult() {
          $('.js-mix-and-match--item-option', context).once('js-cartridge-step').each(function() {
            var $cartridgeProdElem = $(this);
            $cartridgeProdElem.on('click', function() {
              var $cartridgeProdParent = $cartridgeProdElem.closest('.js-mix-and-match--item-option--wrapper');
              var eventAction = $cartridgeProdParent.find('.mix-and-match--title').text().trim();
              var eventLabel = $cartridgeProdElem.data('final-step-title');
              cartridgeProdId = $cartridgeProdElem.data('prodid');
              cartridgeProdSku = $cartridgeProdElem.data('sku');
              trackEvent('customidfinder_Step2', 'diagnostics | Custom ID Finder', eventAction, eventLabel);
              trackFinalResult();
            });
          });
        }

        function trackFinalResult() {
          var eventLabel = baseProdId + ',' + cartridgeProdId + ' - ' + baseProdSku + ',' + cartridgeProdSku;
          trackEvent('customidfinder_complete', 'diagnostics | Custom ID Finder', 'complete', eventLabel);
        }

        function initCliniqueIdTagging() {
          trackBaseResult();
          trackCartridgeResult();
        }
      }
      // Cliniqueid tagging end.

      // Clinique Findation Tagging Start
      $(window).on('load', function() {
        if (window.Findation && analyticsSettings) {
          trackFindationTagging();
        }
      });

      function trackFindationTagging() {
        var Findation = window.Findation || {};
        if (Findation.embedded) {
          trackEvent('findation_widget_presented', 'findation', 'widget', 'Presented');
        }

        Findation.widgetDidOpen = function() {
          trackEvent('findation_widget_opened', 'findation', 'widget', 'Opened');
        };

        Findation.widgetDidClose = function() {
          trackEvent('findation_widget_closed', 'findation', 'widget', 'Closed');
        };

        // Need to be enhanced
        Findation.onSearchComplete = function(data) {
          var resultLength = data.countShadesEntered - 1;
          if (data.matchSuccess) {
            var enteredShade = 'Shades  Entered - ' + data.shadesEntered[resultLength].brandName + ' | ' + data.shadesEntered[resultLength].productName + ' | ' + data.shadesEntered[resultLength].shadeName;
            var resultShade = 'Recommended  Shade - ' + data.recommendedShade;
            trackEvent('findation_results_returned', 'findation', 'results', enteredShade + ' | ' + resultShade);
          } else {
            trackEvent('findation_results_failed', 'findation', 'Widget No Recommendation', 'Widget No Recommendation');
          }
        };
      }

      // virtual Appointment Booking Tagging start.
      if ($('body').hasClass('section-virtual-skin-school')) {
        $(document).on('click', '.js-elc-button', function() {
          var $elem = $(this);
          var clickText = $elem.text().trim();
          var sessionName = $elem.closest('.basic-tout__inner').find('.content-block__line--header .mantle-custom-text').text().trim();
          trackEvent('virtualAppointmentBooking', 'virtual appointment booking', clickText, sessionName);
        });
      }
      // virtual Appointment Booking Tagging end.

      // Cart Overlay Tagging start - Tracking cart overlay - offers tab.
      $(document).on('click', '.js-cart-overlay-tab', function() {
        var value = $(this).data('tab-name');
        if (value === 'offers') {
          trackEvent('cart overlay', 'ecommerce', 'offer tab - cart overlay', 'click');
          if ($('.cart-confirm-wrapper__offer-section-label').length > 0) {
            eventObj.promo_pos = ['module'];
            eventObj.promo_name = [$('.cart-confirm-wrapper__offer-description').text().trim()];
            eventObj.promo_creative = ['Cart Overlay'];
            eventObj.promo_id = ['module-Cart Overlay-' + $('.cart-confirm-wrapper__offer-description').text().trim()];
            site.track.evtView(eventObj);
          }
        } else if (value === 'products') {
          trackEvent('cart overlay', 'ecommerce', 'product tab - cart overlay', 'click');
        }
      });

      // Module open close click
      $(document).on('click', '.accordion-wrapper__header', function() {
        var $currentElem = $(this);
        var evtLabel = $(this).text().trim();
        var requiredClass = $(this).attr('class');

        if ($currentElem.closest('.sample-items').length === 1 && $currentElem.closest('#samples-panel').length === 1) {
          return;
        }
        if (requiredClass.indexOf('accordion-expand') > -1) {
          trackEvent('checkout module open', 'ecommerce', 'module open', evtLabel);
        } else {
          trackEvent('checkout module close', 'ecommerce', 'module close', evtLabel);
        }
      });

      //Tracking arrows click.
      $(document).on('click', '.section-content ul.flex-direction-nav a', function() {
        var carouselName = $(this).closest('.section-content').find('h2.section-header').text().trim();
        trackEvent('checkout arrow click', 'ecommerce', 'arrow click', carouselName);
      });

      //Tracking arrows click mobile.
      $(document).on('touchend', '.section-content ul.flex-direction-nav a', function() {
        var carouselName = $(this).closest('.accordion-wrapper').find('h2.section-header').text().trim();
        trackEvent('checkout arrow click', 'ecommerce', 'arrow click', carouselName);
      });

      //Tacking Offer link click.
      $(document).on('click', '.cart-confirm-wrapper__offer-cta-link', function() {
        eventObj.enh_action = 'promo_click';
        eventObj.promo_pos = ['module'];
        eventObj.promo_name = [$(this).closest('.cart-confirm-wrapper__offer').find('.cart-confirm-wrapper__offer-description').text().trim()];
        eventObj.promo_creative = ['Cart Overlay'];
        eventObj.promo_id = ['module-Cart Overlay-' + $(this).closest('.cart-confirm-wrapper__offer').find('.cart-confirm-wrapper__offer-description').text().trim()];

        trackEvent('content_module_click', 'ecommerce', 'promotion click', 'content module click | ' + [$('.cart-confirm-wrapper__offer-description').text()], eventObj);
      });

      // Tracking need help links.
      $(document).on('click', '.need-help-link a', function() {
        trackEvent('click', 'ecommerce', 'cart', 'need-help - ' + $(this).text().trim());
      });

      // Tracking checkout.
      $(document).on('click', '.checkout-paypal-btn', function() {
        trackEvent('paypal', 'ecommerce', 'cart', 'paypal checkout');
      });

      // Tracking gift wrap.
      $(document).on('click', '.js-gift-wrap-submit-btn', function() {
        var data = site.track.refreshData() || {};
        trackEvent('gift wrap', 'ecommerce', 'gift wrap - cart', data.cart_product_id);
      });

      // Tacking rewards view
      site.elcEvents.addListener('tealium:loaded', function() {
        if (document.URL.indexOf('cart') > -1) {
          $(window).on('scroll.viewport', function() {
            var module = document.querySelector('.header-reward-benifits,.loyalty-offer-section .loyalty-offer-section__title');
            if (isInViewport(module) && !$(module).hasClass('js-viewport-fired')) {
              $(module).addClass('js-viewport-fired');
              eventObj.promo_pos = ['module'];
              eventObj.promo_name = [$('.header-reward-benifits').text().trim()];
              eventObj.promo_creative = ['View Cart Page'];
              eventObj.promo_id = ['module-View Cart Page-' + eventObj.promo_name];
              site.track.evtView(eventObj);
              $(window).off('scroll.viewport');
            }
          });
        }
      });

      // Tacking rewards view mobile
      site.elcEvents.addListener('tealium:loaded', function() {
        if ($('.field-mobile-template').length > 0 && document.URL.indexOf('cart') > -1) {
          $(window).on('scroll.viewport', function() {
            var module = document.querySelector('.trans_detail_item .section-loyalty-offers .section-header');
            if (isInViewport(module) && !$(module).hasClass('js-viewport-fired-mobile')) {
              $(module).addClass('js-viewport-fired-mobile');
              eventObj.promo_pos = ['module'];
              eventObj.promo_name = [$('.section-loyalty-offers .section-header').text().trim()];
              eventObj.promo_creative = ['View Cart Page'];
              eventObj.promo_id = ['module-View Cart Page-' + eventObj.promo_name];
              site.track.evtView(eventObj);
              $(window).off('scroll.viewport');
            }
          });
        }
      });

      // Tracking rewards click.
      $(document).on('click', 'form[name="offer_code"] .offer_apply input[value="Redeem"],form[name="offer_code"] .offer_apply .btn-shopnow', function() {
        eventObj.promo_pos = ['module'];
        eventObj.promo_name = [$('.flex-active-slide .offer-code-wallet__item').data('offer-code')];
        eventObj.promo_creative = ['sample'];
        eventObj.promo_id = ['module-sample-' + $('.flex-active-slide .offer-code-wallet__item').data('offer-code')];
        trackEvent('content_module_click', 'samples', 'samples added', eventObj.promo_name, eventObj);
      });

      // Tracking cart overlay close icon.
      $(document).on('click', '.gnav-header-formatter__close', function(e) {
        if ($(e.target).closest('.js-header-gnav-cart__close').length > 0) {
          trackEvent('cart overlay', 'ecommerce', 'cart overlay close', 'close');
        }
      });

      // Tracking clicking outside cart overlay.
      $('body').on('mousedown touchdown', function(e) {
        var value = $(this).attr('class') ? $(this).attr('class') : '';
        if (value.indexOf('active-gnav') > -1 && $('.header-gnav-cart .js-header-gnav-cart__overlay').hasClass('visible') && $(e.target).closest('.header-gnav-cart').length === 0) {
          trackEvent('cart overlay', 'ecommerce', 'cart overlay close', 'close');
        }
      });

      $(document).on('mousedown touchdown', '.loyalty-offer-section__header,.viewcart-banner__accordion,.order-summary-header,.offer-code-panel .offer-code-panel__title', function () {
        var click_value = $(this).siblings('.loyalty-offer-section__content').is(':visible') ? $(this).siblings('.loyalty-offer-section__content').is(':visible') : $(this).siblings('.js-accordion-content').is(':visible') ? $(this).siblings('.js-accordion-content').is(':visible') : $(this).siblings('.loyalty-find-out-more').is(':visible') ? $(this).siblings('.loyalty-find-out-more').is(':visible') : $(this).siblings('.order-summary__content').is(':visible') ? $(this).siblings('.order-summary__content').is(':visible') : $(this).closest('.offer-code-panel').find('.adpl').is(':visible') ? $(this).closest('.offer-code-panel').find('.adpl').is(':visible') : '';
        var evtLabel = $(this).text().trim();
        if (click_value) {
          trackEvent('checkout module close', 'ecommerce', 'module close', evtLabel);
        } else {
          trackEvent('checkout module open', 'ecommerce', 'module open', evtLabel);
        }
      });

      $(document).on('click', '.links_list .link .overlay-link,.js-checkout-panel-accordion-content a', function() {
        trackEvent('click', 'ecommerce', 'cart', 'need-help - ' + $(this).text().trim());
      });

      $(document).on('mousedown', '.js-continue-buttons .paypal-checkout', function() {
        trackEvent('paypal', 'ecommerce', 'cart', 'paypal checkout');
      });
      // Clinique cart overlay tagging end.

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        // Trigger Alt image event only when alternate images are available
        if ($('body').hasClass('device-pc') && $('.product-full__alt-images-thumb').length > 1) {
          trackAltImageTrackingClickPC();
        } else if ($('body').hasClass('device-mobile')) {
          trackAltImageTrackingMobile();
        }
      }

      // Track Alt image click - desktop
      function trackAltImageTrackingClickPC() {
        $(document).on('click', '.product-full__alt-images-thumb', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('img').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
        });
      }

      // Track Alt image swipe - Mobile
      function trackAltImageTrackingMobile() {
        $(document).on('afterChange', '.product-full__image', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'swipe');
        });
      }

      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.product-full');
        var prodId = prodElem.attr('data-productid');
        var prodName = prodElem.find('.product-full__header .product-full__name').text();
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      // Foundation finder Tagging start.
      if ($('body').hasClass('section-foundation-finder')) {
        trackFoundationTagging();
      }
      function trackFoundationTagging() {
        initFoundationTagging();

        $('.ff-quiz__nav-menu .ff-quiz__nav-item, .refine-results-expanded_view .ff-quiz__nav-item').each(function(key) {
          $(this).attr('data-index', key + 1);
        });

        function trackStartover() {
          $(document).on('click', '.ff-quiz__button.ff-quiz__button--start', function() {
            var eventAction = $('.ff-quiz__button.ff-quiz__button--start').text().trim();
            trackEvent('diagtools', 'diagnostic tool - foundation finder', eventAction, 'click');
            trackNextPage('/foundation-finder/step1', '/step1', 'foundation-finder - Step 1');
          });
        }

        function trackfinderStep() {
          $(document).on('quiznextStep', function(event, analyticsObj) {
            var quizQuestion = analyticsObj.quizLabel;
            var quizAnswer = analyticsObj.quizValue;
            var stepCount = analyticsObj.quizStep + 1;
            var $quizSkip = analyticsObj.quizNav;
            var skipEventAction = $quizSkip[0].innerText;
            var eventLabel = quizQuestion + ' - ' + quizAnswer;
            var eventObj = {};
            eventObj.page_url = '/foundation-finder/step' + analyticsObj.quizStep;
            eventObj.page_name = 'foundation-finder - Step ' + analyticsObj.quizStep;
            if (quizAnswer && stepCount !== 2) {
              trackEvent('diagtools', 'diagnostic tool - foundation finder', 'questions', eventLabel, eventObj);
            } else if ($quizSkip.hasClass('ff-quiz__button--next')) {
              trackEvent('diagtools', 'diagnostic tool - foundation finder', skipEventAction, 'click', eventObj);
            }
            if (stepCount !== 8 && stepCount !== 2) {
              trackNextPage('/foundation-finder/step' + stepCount, '/step' + stepCount, 'foundation-finder - Step ' + stepCount);
            }
          });
        }

        function trackOverlay() {
          $(document).on('click', '.foundation-finder_undertone_selection .undertone-finding, #closeButton', function() {
            var overlayName = $('.untertone-finding_popup .undertone-finding').text().trim();
            var overlayCheck = $(this).hasClass('undertone-finding');
            var eventLabel = overlayCheck ? 'displayed' : 'closed';
            trackEvent('diagtools', 'diagnostic tool - foundation finder', 'overlay', eventLabel + ' - ' + overlayName);
          });
        }

        function trackSliderImage() {
          $(document).on('click', '.ff-quiz__slide--2 .slick-slide', function() {
            var quizQuestion = $('.foundation-finder_skintone_selection').data('question-key');
            var quizAnswer = $(this).find('.lazyloaded.active').data('answer');
            var stepCount = $(this).closest('.ff-quiz__slide--2').data('slick-index') + 1;
            var eventLabel = quizQuestion + ' - ' + quizAnswer;
            trackEvent('diagtools', 'diagnostic tool - foundation finder', 'questions', eventLabel);
            trackNextPage('/foundation-finder/step' + stepCount, '/step' + stepCount, 'foundation-finder - Step ' + stepCount);
          });
        }

        function trackCTAbuttons() {
          $(document).on('click', '.foundation-finder-save, #perfect-match a, .result-start-over-link, .refine-my-results, .recommendation-header .start-link', function() {
            var $foundationCtaElem = $(this);
            var eventAction = $foundationCtaElem.text().trim();
            var emptyResultCheck = $(this).hasClass('start-link');
            if (emptyResultCheck) {
              var eventObj = {};
              eventObj.page_url = '/foundation-finder/result';
              eventObj.page_name = 'foundation-finder - result';
              trackEvent('diagtools', 'diagnostic tool - foundation finder', eventAction, 'click', eventObj);
            } else {
              trackEvent('diagtools', 'diagnostic tool - foundation finder', eventAction, 'click');
            }
          });
        }

        function trackResultProductClick() {
          $(document).on('click', '#mpp-product-grid .mpp-product, .recommended-product-description .btn-add-to-bag', function() {
            var productHref = $(this).find('.product-thumb').attr('href') || $(this).closest('.recommended-products').find('.read_all_review').attr('href');
            var producSplit = productHref.split('/');
            var finderProductId = 'PROD' + producSplit[3];
            var finderProductPosition = $(this).data('index') || '1';
            var addtoBagClass = $(this).hasClass('btn-add-to-bag');
            var eventObj = {};
            eventObj.enh_action = 'product_click';
            eventObj.product_position = [finderProductPosition];
            eventObj.product_list = [location.pathname + '/results'];
            eventObj.product_id = [finderProductId];
            trackEvent('diagtools', 'ecommerce', 'product click', 'product click', eventObj);
            if (addtoBagClass) {
              trackAddtoBag(finderProductId);
            }
          });
        }

        function trackAddtoBag(productId) {
          var objView = {
            enh_action: 'detail',
            product_id: [productId]
          };
          site.track.evtView(objView);
        }

        function trackBredcrumbClick() {
          $(document).on('click', '.ff-quiz__nav-menu .ff-quiz__nav-item.selected, .refine-results-expanded_view .ff-quiz__nav-item', function() {
            var quizStepNav = $(this).data('index');
            trackNextPage('/foundation-finder/step' + quizStepNav, '/step' + quizStepNav, 'foundation-finder - Step ' + quizStepNav);
          });
        }

        function resultPageTagging() {
          $(document).on('filterResult', function() {
            $('.recommended-product-view').addClass('js-result-products');
            $('.mpp-product').addClass('js-result-products');
            var $menuItems = $('.foundation-finder-results');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;
            $menuItems.find('.js-result-products').each(function(index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              var productId = $elem.data('product-id');
              productImpressionIds.push(productId);
              productPositions.push(index + 1);
              productImpressionList.push(location.pathname + '/results');
              $(this).attr('data-index', index + 1);
            });

            var objView = {
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: location.origin + location.pathname + '/results',
              page_url: '/foundation-finder/result',
              page_name: 'foundation-finder - result'
            };
            site.track.evtView(objView);

            $('.refine-results-expanded_view .ff-quiz__nav-item').each(function(key) {
              $(this).attr('data-index', key + 1);
            });
          });
        }

        function trackNextPage(pageurl, plocation, pname) {
          var objView = {};
          Object.assign(objView, {
            page_url: pageurl,
            location: location.origin + location.pathname + plocation,
            page_name: pname
          });
          site.track.evtView(objView);
          objView = {};
        }

        function initFoundationTagging() {
          trackStartover();
          trackfinderStep();
          trackOverlay();
          trackSliderImage();
          trackCTAbuttons();
          trackResultProductClick();
          trackAddtoBag();
          trackBredcrumbClick();
          resultPageTagging();
        }
      }
      // Foundation finder Tagging end.

      // Blog page Tagging start.
      if ($('body').hasClass('section-thewink-redesign') || $('body').hasClass('section-thewink')) {
        var blogbannerElement = [
          '.js-top-stories .js-article-img',
          '.js-top-stories .js-article-cat-tag',
          '.js-top-stories .js-article-title'
        ];

        $(document).on('click', '.js-tWMenuItem ', function () {
          var eventLabel = $(this).text().trim();

          trackEvent('blog', 'blog', 'blog category selection', eventLabel);
        });

        $(document).on('click', blogbannerElement.join(', '), function () {
          var $blogImageElem = $(this);
          var eventLabel = $blogImageElem.closest('a').attr('href');
          var blogSubtitle = $.trim($('.article-subtitle').text());
          var blogDiscription = blogSubtitle.split(' ');

          eventObj.content_title = $blogImageElem.text().trim();
          eventObj.content_author = 'null';
          eventObj.content_category = $blogImageElem.find('.article-cat-tag').text().trim();
          eventObj.content_subcategory = 'null';
          eventObj.content_word_count = blogDiscription.length;
          trackEvent('blog', 'blog', 'blog article selection', eventLabel, eventObj);
        });

        window.onload = function () {
          if ($('div').hasClass('shop_the_story') && $('body').hasClass('section-thewink')) {
            var $menuItems = $('.shop_the_story');
            var productImpressionIds = [];
            var productPositions = [];
            var productImpressionList = [];
            var $elem;

            $menuItems.find('.product').each(function (index, elem) {
              if (!$(elem).length) {
                return;
              }
              $elem = $(elem);
              productImpressionIds.push($elem.attr('data-productid'));
              productPositions.push(index + 1);
              productImpressionList.push('/blog list');
              $(this).attr('data-index', index + 1);
            });

            var objView = {
              event_name: 'blog',
              event_category: 'ecommerce',
              event_action:'product carousel display',
              event_label:'product carousel display',
              product_impression_list: productImpressionList,
              product_impression_position: productPositions,
              product_impression_id: productImpressionIds,
              location: window.location.href
            };

            site.track.evtLink(objView);
            if ($('div').hasClass('eh_hero_article_images')) {
              var trackName = $('.blog-post').attr('trackname');
              var trackSplit = trackName.split('|');
              var promoCreative = trackSplit[0];
              var promoName = $('.article .artist_name').text().trim();     
              site.track.evtView({
                promo_pos: ['module'],
                promo_creative: [promoCreative],
                promo_name: [promoName],
                promo_id: ['module - ' + promoCreative + ' - ' + promoName]
              });
            }
          }
        };

        $(document).on('click', '.carousel-controls .slick-arrow', function () {
          var $slickElem = $('.slick-list .slick-active');
          var prodArray = [];

          $slickElem.each(function () {
            prodArray.push($(this).find('.js-product-brief').attr('data-product-id'));
          });

          var eventLabel = prodArray.join('|');
          trackEvent('blog', 'blog', 'blog product carousel - arrow click', eventLabel);
        });

        $(document).on('click', '.shop_the_story .product', function () {
          var $blogImageElem = $(this);
          var blogResultId = $blogImageElem.data('productid');
          var blogProdPosition = parseInt($blogImageElem.attr('data-index'));

          eventObj.enh_action = 'product_click';
          eventObj.product_position = [blogProdPosition];
          eventObj.product_list = ['blog list'];
          eventObj.product_id = [blogResultId];
          trackEvent('blog', 'ecommerce', 'product click', 'product click', eventObj);
        });

        $(document).on('click', '.js-eh-flip-on-hover .read_more a', function () {
          var eventLabel = $(this).attr('href');

          trackEvent('blog', 'blog', 'blog - recommended for you carousel', eventLabel);
        });

        $(document).on('click', '.js-infinite-scroll__load-more, ul.see-all-container li a', function () {
          var eventLabel = $(this).text().trim();

          trackEvent('blog', 'blog', 'blog - link click', eventLabel);
        });

        $(document).on('SocialShareIcon', function (event, data) {
          var eventLabel = data;

          trackEvent('social share', 'social', 'share - blog page', eventLabel);
        });
      }
      // Blog page Tagging end.

      $(document).on('click', '.js-hero-link--1', function () {
        var eventLabel = $(this).find('a span').text().trim();
        trackEvent('sheer_ID', 'sheer_ID', 'click', eventLabel);
      });

      // trackEvent common function call start.
      function trackEvent(eName, eCategory, eAction, elabel, eventObj) {
        eventObj = eventObj || {};
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: elabel
        });
        site.track.evtLink(eventObj);
      }
      // Event track common function call end.

      // Module open close clicks
      $(document).on('mousedown touchdown', '.js-samples-accordion, .js-accordion-header, .samples__header-text', function () {
        var $currentElem = $(this);
        var $samplesPanelElem = $currentElem.closest('#samples-panel');
        var $targetElem =  $samplesPanelElem.length ?  $samplesPanelElem : $currentElem.closest('.sample-items');
        var $accordionContentElem = $targetElem.find('.js-accordion-content');
        var clickSense = $accordionContentElem.length ? $accordionContentElem.is(':visible') : $targetElem.find('.samples__content').is(':visible');
        var evtLabel = $(this).text().trim();

        if ($targetElem.length === 1) {
          if (clickSense) {
            trackEvent('sample_module_close', 'samples', 'module_close', evtLabel);
          } else {
            trackEvent('sample_module_open', 'samples', 'module_open', evtLabel);
          }
        }
      });

      // Tracking arrow clicks
      $(document).on('mousedown touchdown', '.slick-slider .slick-prev,.slick-slider .slick-next, .slick-slider .samples__previous, .slick-slider .samples__next', function () {
        var $currentElem = $(this);
        var $sampleElem = $currentElem.closest('#samples-panel');
        var $samplePanel = $sampleElem.length ? $sampleElem : $currentElem.closest('.sample-items');
        var carouselName = '';
        var $samplesHeaderElem = $currentElem.closest('.js-accordion-content').siblings('.viewcart-samples__header');
        var $accordionHeaderElem = $samplePanel.find('.js-accordion-header');

        if ($samplesHeaderElem.length === 1) {
          carouselName = $samplesHeaderElem.text().trim();
        } else if ($accordionHeaderElem.length === 1) {
          carouselName = $accordionHeaderElem.text().trim();
        } else {
          carouselName = $samplePanel.find('.samples__header-text').text().trim();
        }

        if (!($currentElem.hasClass('disabled') || $currentElem.hasClass('slick-disabled')) && carouselName) {
          if ($samplePanel.length === 1) {
            trackEvent('sample_arrow_click', 'samples', 'arrow_click', carouselName);
          } else {
            trackEvent('checkout arrow click', 'ecommerce', 'arrow click', carouselName);
          }
        }
      });

      // Remove sample tracking
      $(document).on('click', '.sample-select-button, .js-sample-item-select-button', function () {
        var $currentElem = $(this);
        var $prodContainerElem = $currentElem.closest('.product__description, .samples__details, .details');
        var $targetElem = $prodContainerElem.length > 0 ? $prodContainerElem.first() : $currentElem;
        var $skuSelected = $targetElem.find('.sample-select');
        var skuId = $currentElem.hasClass('js-sample-item-select-button') ? $currentElem.attr('data-sku-id') : $skuSelected.val();
        var skuName = '';
        var removeSample = false;
        var $productElem = $targetElem.find('.product_name, .product-name');
        var $sampleElem = $targetElem.find('.sample_product_name');
        var $samplePageElem = $('body').attr('id') || $('body').attr('class');
        var separateSamplePage = ($samplePageElem && $samplePageElem.indexOf('sample') > -1) ? true : false;

        if ($productElem.length > 0) {
          skuName = $productElem.first().text().trim();
        } else if ($sampleElem.length === 1) {
          skuName = $sampleElem.text().trim();
        } else {
          skuName = $targetElem.find('.sample-name').text().trim();
        }

        if ($currentElem.hasClass('js-sample-item-select-button')) {
          removeSample = $currentElem.closest('.js-sample-product-item').hasClass('selected');
        } else if ($currentElem.hasClass('js-sample-select-button') && jQuery.cookie('LOCALE') !== 'en_AU') {
          removeSample = $skuSelected.prop('checked');
        } else {
          removeSample = !$skuSelected.prop('checked');
        }

        skuId = (skuId && skuId.toUpperCase().includes('SKU')) ? skuId : 'SKU' + skuId;
        if (removeSample) {
          trackEvent('samples', 'samples', 'samples_remove', skuName + ' - ' + skuId);
        } else if (separateSamplePage) {
          trackEvent('samples', 'samples', 'samples_added', skuName + ' - ' + skuId);
        }
      });

      // Customer Service Hub Tagging start.
      if ($('body').hasClass('section-customer-care')) {
        $(document).on('click', '.js-analytics-header', function () {
          var $elem = $(this);
          var eventLabel = $elem.find('.js-mantle-custom-text').text().trim();

          trackEvent('customer_server_hub', 'customer_server_hub', 'click', eventLabel);
        });
      }
      // Customer Service Hub Tagging end.

      // Loyalty signup popup Tagging start.
      $(document).on('click', '.js-loyalty-join-now-btn a', function () {
        $(document).trigger('welcome_overlay_displayed', [{ template_name: 'loyalty_offer_signup' }]);
      });

      $(document).on('click', '#cboxContent #cboxClose', function () {
        var $currentElem = $(this);
        var $loyaltyContainerElem = $currentElem.closest('#cboxContent').find('.js-loyalty-popover');

        if ($loyaltyContainerElem.length > 0) {
          trackEvent('loyalty signup overlay', 'ecommerce', 'loyalty signup overlay close', 'close');
        }
      });
      // Loyalty signup popup Tagging end.

      if ($('div').hasClass('js-elc-regimen-product-carousel')) {
        var regimenProductCarouselEventFn = function () {
          var data = site.track.refreshData();

          if (data && !jQuery.isEmptyObject(data)) {
            site.track.evtLink({
              event_name: 'product_impression',
              event_category: 'ecommerce',
              event_action: 'view_item_list',
              event_label: 'view_item_list',
              event_noninteraction: true,
              product_impression_base_id: data.product_impression_base_id,
              product_impression_brand: data.product_impression_brand,
              product_impression_category: data.product_impression_category,
              product_impression_category_id: data.product_impression_category_id,
              product_impression_category_name: data.product_impression_category_name,
              product_impression_id: data.product_impression_id,
              product_impression_list: data.product_impression_list,
              product_impression_name: data.product_impression_name,
              product_impression_position: data.product_impression_position,
              product_impression_price: data.product_impression_price,
              product_impression_product_code: data.product_impression_product_code,
              product_impression_shade: data.product_impression_shade,
              product_impression_short_desc: data.product_impression_short_desc,
              product_impression_size: data.product_impression_size,
              product_impression_sku: data.product_impression_sku,
              product_impression_sku_large_image_url: data.product_impression_sku_large_image_url,
              product_impression_sku_small_image_url: data.product_impression_sku_small_image_url,
              product_impression_small_image_url: data.product_impression_small_image_url,
              product_impression_upc: data.product_impression_upc,
              product_impression_url: data.product_impression_url,
              product_impression_was_price: data.product_impression_was_price
            });
          }
        };

        if (window.addEventListener) {
          window.addEventListener('load', regimenProductCarouselEventFn, false);
        } else if (window.attachEvent) {
          window.attachEvent('onload', regimenProductCarouselEventFn);
        }
      }

      self.attached = 1;
    }
  };
}(window.site || {}, jQuery));
